<template>
  <div>
    <h3 class="text-center text-xl font-bold">Buy Wallet Code</h3>

    <div class="flex flex-col gap-4 mt-4">
      <div class="flex md:flex-row flex-col gap-4">
        <vs-input
          v-model="amount"
          type="number"
          placeholder="Amount *"
          class="w-full"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <template v-if="isAmountInvalid && amount" #message-danger>
            Amount should be greater then 5 and lower then 500!
          </template>
          <template #icon>
            <i class="bx bx-dollar" />
          </template>
        </vs-input>

        <vs-input
          v-model="mailAddress"
          placeholder="Email address *"
          class="w-full"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <template v-if="isMailInvalid && mailAddress" #message-danger>
            Invalid Mail!
          </template>
          <template #icon>
            <i class="bx bx-envelope" />
          </template>
        </vs-input>
      </div>

      <div class="flex md:flex-row flex-col gap-4">
        <vs-input
          v-model="name"
          placeholder="Name *"
          class="w-full"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <template #icon>
            <i class="bx bx-user" />
          </template>
          <template v-if="isNameInvalid && name" #message-danger>
            Invalid Name!
          </template>
        </vs-input>

        <vs-input
          v-model="surname"
          placeholder="Surname *"
          class="w-full"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <template #icon>
            <i class="bx bxs-user" />
          </template>
          <template v-if="isSurnameInvalid && surname" #message-danger>
            Invalid Surname!
          </template>
        </vs-input>
      </div>

      <vue-phone-number-input
        :border-radius="12"
        dark-color="#1e2124"
        dark
        v-model="phoneNumber"
        :required="true"
        @update="onPhoneUpdate"
      />

      <div class="relative h-10">
        <vs-button
          class="h-10 w-full p-0 m-0 absolute z-0"
          color="#7d33ff"
          relief
          @click="buyWallet"
          :disabled="
            isMailInvalid ||
            isNameInvalid ||
            isAmountInvalid ||
            isSurnameInvalid ||
            !isPhoneValid ||
            isLoading
          "
          :loading="isLoading"
        >
          Buy Wallet Code
        </vs-button>
      </div>
    </div>

    <div class="w-full mt-4" v-if="payment">
      <iframe :src="payment" class="w-full" style="height: 740px"> </iframe>
    </div>
  </div>
</template>

<script>
import { createPayment } from "@/services/ApiService";

export default {
  name: "BuyWallet",
  data() {
    return {
      mailAddress: undefined,
      payment: "",
      isLoading: false,
      name: undefined,
      surname: undefined,
      phoneNumber: undefined,
      amount: 5,
      isPhoneValid: false,
      realPhoneNumber: undefined,
    };
  },
  methods: {
    async buyWallet() {
      this.payment = await createPayment({
        amount: Number(this.amount),
        email: this.mailAddress,
        name: this.name,
        surname: this.surname,
        gsm: this.realPhoneNumber,
      });
    },
    onPhoneUpdate(phone) {
      this.isPhoneValid = phone.isValid;

      this.realPhoneNumber = phone.formattedNumber;
    },
  },
  computed: {
    isMailInvalid() {
      const mail = this.mailAddress;
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return !regex.test(mail);
    },
    isAmountInvalid() {
      const amount = this.amount;
      return amount < 1 || amount > 500;
    },
    isNameInvalid() {
      const name = this.name;
      if (name && name.length < 2) {
        return true;
      }
      const regex = /^[a-zA-Z]+$/;
      return !regex.test(name);
    },
    isSurnameInvalid() {
      const surname = this.surname;
      if (surname && surname.length < 2) {
        return true;
      }
      const regex = /^[a-zA-Z]+$/;
      return !regex.test(surname);
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .vue-phone-number-input {
  input {
    border: none;
  }

  button {
    background-color: #1e2124;
  }

  .vue-recycle-scroller__item-view {
    opacity: 1;
  }
}

</style>
