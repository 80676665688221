<template>
  <div class="flex items-center justify-center">
    <div class="w-full flex flex-col gap-4 sm:w-max-5xl">
      <div>
        <div
          class="relative flex h-20 gap-2 w-full bg-primary rounded-2xl text-white px-8 justify-between items-center flex-row"
        >
          <i class="bx bxs-wallet text-5xl" />
          <div class="text-2xl font-extrabold">{{ balance }}$</div>
        </div>
      </div>

      <BuyWallet />

      <h3 class="text-center text-xl font-bold">Transactions</h3>

      <vs-table class="w-auto relative" ref="table">
        <template #thead>
          <vs-tr>
            <vs-th class="!w-1/4"> Action</vs-th>
            <vs-th class="!w-1/4"> Status</vs-th>
            <vs-th> Amount</vs-th>
            <vs-th> Date</vs-th>
          </vs-tr>
        </template>
        <template #tbody v-if="transactions">
          <vs-tr :key="i" v-for="(tr, i) in transactions.docs" :data="tr">
            <vs-td>
              {{
                tr.type === 0
                  ? "Wallet Usage"
                  : tr.type === 1
                  ? "Product Subscribe"
                  : "Wallet Purchase"
              }}
            </vs-td>
            <vs-td>
              {{
                tr.status === 0
                  ? "Pending"
                  : tr.type === 1
                  ? "Waiting for approval"
                  : tr.type === 2
                  ? "Approved"
                  : "Failed"
              }}
            </vs-td>
            <vs-td> {{ tr.amount }}$</vs-td>
            <vs-td>
              {{ tr.createdAt | formatTimestamp }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer v-if="transactions">
          <vs-pagination v-model="page" :length="transactions.totalPages" />
        </template>
      </vs-table>

      <h3 class="text-center text-xl font-bold">Redeem Wallet Code</h3>

      <div class="flex flex-row">
        <vs-input
          v-model="walletCode"
          placeholder="Use Wallet Code"
          class="w-full h-14"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <template
            v-if="isWalletCodeInvalid && walletCode !== ''"
            #message-danger
          >
            Invalid wallet code!
          </template>
          <template #icon>
            <i class="bx bx-wallet" />
          </template>
        </vs-input>
        <vs-button
          class="h-10 w-10 m-0 ml-2"
          :class="isWalletCodeInvalid && walletCode !== '' ? 'mt-0' : 'mt-2'"
          icon
          color="#7d33ff"
          relief
          @click="useWallet"
          :disabled="isWalletCodeInvalid || isLoading"
          :loading="isLoading"
        >
          <i class="bx bxs-paper-plane"></i>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyTransactions, useWalletCode } from "@/services/ApiService";
import { syncUser } from "@/services/UserService";
import BuyWallet from "@/views/user/wallet/BuyWallet";

export default {
  name: "Wallet",
  components: { BuyWallet },
  data: () => ({
    page: 1,
    max: 10,
    walletCode: "",
    isLoading: false,
    transactions: null,
  }),
  methods: {
    useWallet() {
      this.isLoading = true;
      const walletCode = this.walletCode;
      useWalletCode(walletCode)
        .then(() => {
          this.isLoading = false;
          this.walletCode = "";
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Wallet code successfully used!",
          });
          syncUser();
          this.getTransactions();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },

    async getTransactions() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.transactions = await getMyTransactions(this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
  },
  computed: {
    balance() {
      return this.$store.state.user?.balance;
    },
    isWalletCodeInvalid() {
      return this.walletCode.length !== 20;
    },
  },
  async mounted() {
    await this.getTransactions();
  },
  watch: {
    page() {
      this.getTransactions();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .vs-input-content input {
    width: 100%;
    height: 2.5rem;
  }
}
</style>
